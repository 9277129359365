const $form = document.querySelector('form#contato')

const setLoading = status => {
  $form.classList.toggle('loading', status)
  console.log($form.classList)
  Object.values(document.querySelectorAll('form#contato [name]'))
    .forEach(input => input[status ? 'setAttribute' : 'removeAttribute']('disabled', true))
}

$form.addEventListener('submit', (e) => {
  e.preventDefault()
  const data = {
    name: document.querySelector('#contato [name=name]').value,
    email: document.querySelector('#contato [name=email]').value,
    phone: document.querySelector('#contato [name=phone]').value,
    subject: document.querySelector('#contato [name=subject]').value,
    message: document.querySelector('#contato [name=message]').value,
  }
  setLoading(true)
  fetch('https://us-central1-emiolo-static.cloudfunctions.net/sendMail', {
    method: 'POST',
    body: JSON.stringify(data)
  })
    .then(() => {
      setLoading(false)
      $form.classList.toggle('success', true)
    })
    .catch(() => {
      setLoading(false)
      $form.classList.toggle('fail', true)
    })
  return false
})

